import React from 'react';

export default function Nouser(){


    return <div>
               <div class="alert alert-info" role="alert">
               No cuenta con un usuario. Solicite uno enviando sus datos a <b>mesadeayuda@frt.utn.edu.ar</b><br/>
Datos Importantes:<br/>
  <ul>
    <li>DNI</li>
    <li>Legajo</li>
    <li>Apellido y Nombres</li>
    <li>Especialidad (Carrera(s) que cursa)</li>
    <li>Año de ingreso.</li>
  </ul> 
El correo debe ser enviado desde su cuenta institucional, para acceder a ella ingrese <a href="https://outlook.office.com/">aquí</a>

               </div>      
           </div>      
}