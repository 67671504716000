import React, {useState, useEffect} from 'react'
import {useLocation} from "react-router-dom";
import request from './shared/helpers/request'
import Spinner from './shared/helpers/Spinner'


export default function MisTramites(){
    const { search } = useLocation();
	const params = new URLSearchParams(search);
	const id = params.get('id')?params.get('id'):null;
  	const [idTramite, setIdTramite] = useState('');
  	const [tramite, setTramite] = useState(null);
  	const [buscarText, setBuscarText] = useState('Buscar');
        
    useEffect(()=>{
      setIdTramite(id);
      if (id) buscarTramite(id);
    },[]);
    

  	const buscarTramite = idTramite => {
   		setBuscarText(<Spinner/>);
   		request.get('api/rutcore/tramites/mistramites?id='+idTramite)
          		.then( response => {
              		//console.log(response.data);
                  if (response.data.titulo)
              		  setTramite(response.data);
                  else
                    setTramite(null);
                  if (response.data.error) alert(response.data.error);
              		setBuscarText('Buscar');})
          		.catch(e => {
            		console.log();
            		setBuscarText('Buscar'); 
          		});
  	};

  	const descargarArchivo = (idTramite, idArchivo, nombreArchivo) => {
  		//console.log('api/rutcore/adjuntos/'+idArchivo+': '+nombreArchivo);
		  request.getFile('api/rutcore/adjuntos/'+idArchivo, nombreArchivo);
      request.put('api/rutcore/tramites/'+idTramite, 
                {definicion: {					  
                      issue: {
                          status_id: 3, done_ratio: 100}}}, null)
            .then(x=> console.log(x));
  	}
	
	return (
		<React.Fragment>		
		<div className="input-group md-form form-sm form-1 pl-0 mb-3">  			
  			<input className="form-control my-0 py-1" type="text" placeholder="Buscar Tramite" aria-label="Search" value={idTramite} onChange={e => setIdTramite(e.target.value)}/>
  			<div className="input-group-append">
    			<button className="btn btn-outline-success" type="button" onClick={()=>buscarTramite(idTramite)}>{buscarText}</button>
  			</div>
		</div>		
    <div className="input-group md-form form-sm form-1 pl-3 mb-3">       
     <fieldset>
          <legend className="bg-secondary text-light">{tramite? tramite.titulo: ''}</legend>
          <p>{tramite? 'Fecha: '+Intl.DateTimeFormat('es').format(Date.parse(tramite.fecha_inicio)):''}</p>
          <p>{tramite? 'Fecha aproximada de resolucion: '+Intl.DateTimeFormat('es').format(Date.parse(tramite.fecha_vencimiento)):''}</p>
          <p>{tramite? 'Estado: '+tramite.estado:''}</p>
     </fieldset> 
    </div>
		<table className="table table-hover">
  			<thead className="thead-dark">
    			<tr>
      				<th scope="col">Fecha</th>
      				<th scope="col">Autor</th> 
              <th scope="col">Comentario</th> 
    			</tr>
  			</thead>
  			<tbody>
    			{tramite?tramite.notas.filter(x => x.texto !== '')
            .map((x, y) => 
		    			<tr key={y}>      				
      						<td>{Intl.DateTimeFormat('es').format(Date.parse(x.fecha))}</td>
      						<td>{x.autor}</td>
      						<td>{x.texto}</td>      						
    					</tr>
    				): null}
  			</tbody>
        <tfoot>
          <tr><td colSpan={3}>Archivos Descargables: {tramite?tramite.adjuntos
                                          .map((x, y) => 
                                            <button 
                                                className="btn btn-link d-block" 
                                                key={y} 
                                                onClick={() =>descargarArchivo(tramite.id, x.id, x.nombre)}>
                                                {x.nombre}</button>):''}</td></tr>
        </tfoot>
		</table>
		</React.Fragment>
	);
}