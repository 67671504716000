import React, {useContext, useState, useEffect} from 'react'
import Categoria from './Categoria'
import Userbutton from './Userbutton'
import Logo from '../assets/images/logo.png'
import UserContext from './../application/userContext';
import useUser from './shared/hooks/useUser';
import {Link} from "react-router-dom";

export default function Navbar(props){
  const contexto = useUser();

  //console.log(contexto.tramites);

	return (
<nav className="navbar navbar-expand-lg navbar-light bg-light">	    
  <Link className="navbar-brand" to="/">
  	 <img src={Logo} width="150" height="60" className="d-inline-block align-top" alt=""/>
  </Link>
  <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarScroll" aria-controls="navbarScroll" aria-expanded="false" aria-label="Toggle navigation">
    <span className="navbar-toggler-icon"></span>
  </button>
  <div className="collapse navbar-collapse" id="navbarScroll">    
    <ul className="navbar-nav mr-auto my-2 my-lg-0 navbar-nav-scroll">      
      
      {contexto.user.iniciado&&contexto.tramites?contexto.tramites.map((x, y) => <Categoria key={y} clave={y} titulo = {x.categoria} opciones={x.tramites}/>):''}
{console.log(contexto.tramites)}
    </ul>    
    <Userbutton/>
  </div>
</nav>
		);
}

Navbar.defaultProps = {
	tramites: []
}