import React from 'react'

export default function Table(props) {
      
      var i=0;

      const crearFila = (columnas, header = false) => {
        var retorno = [];
        if (!header){
          var tabla = document.getElementById('tabla'+props.name);
          var fila = tabla.insertRow();      
        }
          for (var x in columnas){
            i++;
            if (columnas.hasOwnProperty(x)) 
              {
                if (header) retorno.push(<th key={i}>{x}</th>);  
                else {
                  var input = document.createElement('input');
                  input.setAttribute('name', x+'_'+tabla.rows.length);
                  fila.insertCell().appendChild(input);
                }
                
              }
          }          
          if (header){
            retorno.push(<th key={i+1}>
                            <button className="btn btn-dark" 
                                    type="button" 
                                    onClick={()=>crearFila(props.cols)}>
                                    +
                            </button></th>);            
            return (<tr>{retorno}</tr>);
          }else{
            var boton = document.createElement('button');
            boton.setAttribute('type', 'button');
            boton.setAttribute('class', 'btn btn-secondary');
            boton.addEventListener('click', (e)=>removerFila(e));
            boton.innerHTML = '-';
            fila.insertCell().appendChild(boton);  
          }
          
          
      }

      const removerFila = (e) => {          
          e.target.parentNode.parentNode.remove();          
      };


      return (<div key={props.clave} className="form-group">
              <label>{props.titulo}</label>
              <table id={"tabla"+props.name} className="table table-hover">
                <thead className="thead">
                  {crearFila(props.cols, true)}
                </thead>
                <tbody>
                </tbody>
              </table>              
              </div>);
  }