	
export default class TramiteBase{

    constructor (idTramite, proyecto, indicador, asunto, descripcion, plazo, encargado, prioridad, porcentaje, emailSolicitante){


        const inicio = new Date();
        const vencimiento = new Date();
        vencimiento.setDate(inicio.getDate() + plazo);
        while(vencimiento.getDay()<1 || vencimiento.getDay()>5)        
            vencimiento.setDate(vencimiento.getDate() + 1);
        this.tramiteDefinition = 
        {
            "issue": {
            "project_id": proyecto, 
            "parent_issue_id": indicador, //2166
            "subject": asunto,
            "description": descripcion,
            "start_date": inicio.toISOString().split('T')[0],
            "due_date": vencimiento.toISOString().split('T')[0],
            "assigned_to_id": encargado,//336
            "priority_id": prioridad, //2
            "status_id" : 2,
            "done_ratio": porcentaje,
            "custom_fields": []
            }            
        };
        this.idTramite = idTramite;
        this.emailSolicitante = emailSolicitante;
        
    
    }

    getTramite(){
        return {"idTramite": this.idTramite, definicion: this.tramiteDefinition, emailSolicitante: this.emailSolicitante};
    }
}

    
