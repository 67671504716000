import React from 'react'


export default function Carousel(props){
	

	return (
		<div id="carouselExampleSlidesOnly" className="carousel slide" data-ride="carousel">
  			<div className="carousel-inner">    			
      			{props.img.map((x, y) => 
					<div key={y} className={y===0?"carousel-item active":"carousel-item"}>
    					<img className="d-block w-100 mb-3" src={x} style={{height: 400}} alt="First slide"/>
					</div>)}    			
  			</div>
		</div>);
}

Carousel.defaultProps = {
	img: []

};