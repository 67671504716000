export const msalConfig = {
  auth: {
    clientId: "968ebc18-e0f8-4669-b4f7-d271471bc17f",
    authority: "https://login.microsoftonline.com/843d9746-0674-48bf-a402-a45cd06f541a",
    redirectUri: process.env.REACT_APP_MSLOGIN,
  },
  cache: {
    cacheLocation: "sessionStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  }
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
 scopes: ["User.Read"]
};

// Add the endpoints here for Microsoft Graph API services you'd like to use.
export const graphConfig = {
    graphMeEndpoint: "https://graph.microsoft.com/v1.0/me"
};