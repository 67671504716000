import React, {useEffect, useState, useContext} from 'react'
import request from './shared/helpers/request';
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "./shared/auth/authConfig";
import {Link} from "react-router-dom";
import UserContext from './../application/userContext';
import Spinner from './shared/helpers/Spinner';
import { toast } from 'react-toastify';
import {useHistory} from "react-router-dom";

export default function Userbutton(){  
  const contexto = useContext(UserContext);//useUser();    
  const [textoBoton, setTextoBoton] = useState('Iniciar Sesion');
  const [intervaloSesion, setIntervaloSesion] = useState(null);
  const history = useHistory();

  useEffect(()=>{
    var miUsuario = sessionStorage.getItem('user');
    if (miUsuario){
        miUsuario = JSON.parse(miUsuario);
        const u = {...contexto.user, 
                          iniciado: true, 
                          fullName: miUsuario.fullName, 
                          email: miUsuario.email,
                          id: miUsuario.id,
                          legajos: miUsuario.legajos,
                          encuesta: miUsuario.encuesta,
                          roles: miUsuario.roles
        };        
        contexto.setUsuario(u);  
        const tramites = JSON.parse(sessionStorage.getItem('tramites'));
        contexto.setTramites(tramites);
        console.log(contexto.user);
        let interval = setInterval(checkSession, 1000);
        setIntervaloSesion(interval);        
    }    
  }, []);

  function handleLogin(instance) {            
    if (contexto.user.iniciado) return false;
    setTextoBoton(<Spinner/>);
    instance.loginPopup(loginRequest)
            .then(res => {
              //console.log(res);                         
              sessionStorage.setItem('jwtToken', res.accessToken);
              sessionStorage.setItem('expira', res.expiresOn);                            
              request.get('api/rutcore/usuarios/login')
                .then(response => {
                  //console.log(response.data)
                  if (response.data.error){
                    setTextoBoton(res.account.name);
                    history.push('/nouser');
                  }else{
                      const usuario = {...contexto.user, 
                          iniciado:true, 
                          fullName: res.account.name, 
                          email: res.account.username,
                          id: res.account.localAccountId,
                          legajos: response.data.legajos,
                          encuesta: response.data.encuesta,
                          roles: response.data.roles
                      };
                      contexto.setUsuario(usuario);
                      let interval = setInterval(checkSession, 1000);
                      setIntervaloSesion(interval);
                      //console.log(usuario);
                  }
                  
                  //localStorage.setItem('user', JSON.stringify(usuario));
                  request.get('/api/rutcore/tramites/listado')
                .then(response => {
                  const tramites = response.data;

                  contexto.setTramites(tramites);
                  sessionStorage.setItem('tramites', JSON.stringify(tramites));
                  //console.log(tramites);
                  
                })
                .catch(e => console.log(e));
                });
              
              
            })
            .catch(e => console.error(e));
  }
  const { instance } = useMsal();

  const cerrarSesion = () => {        
    clearInterval(intervaloSesion);
    contexto.delUsuario();    
    setTextoBoton('Iniciar Sesion');
  };

  function checkSession(){
    let expira = sessionStorage.getItem('expira');
    let d = new Date(expira);
    let n = new Date();
    let diff = (d.getTime() - n.getTime()) / 1000;
    if (expira && diff < 0){        
      cerrarSesion();      
      toast.warning('Atencion: La sesion ha expirado.', {
        position: "top-center",
        autoClose: false,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        });
    }
  }//<Link className="dropdown-item" to="/mistramites">Mis Tramites</Link>

	const userOpciones = () => contexto.user.iniciado?<React.Fragment><button type="button" className="btn dropdown-toggle dropdown-toggle-split" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
    			<span className="sr-only">Toggle Dropdown</span>
  			</button>
  			<div className="dropdown-menu">    			
          {/*contexto.user.roles.includes('admin')?<Link className="dropdown-item" to="/admin">Administrador</Link>:''*/}
          <Link className="dropdown-item" to="/mistramites">Mis Tramites</Link>
    			<Link className="dropdown-item" to="/" onClick={cerrarSesion}>Salir</Link>
  			</div></React.Fragment>:"";

	return (
		<React.Fragment>
		<div className="btn-group">
  			<button type="button" className={contexto.user.iniciado?"btn":"btn btn-primary"} onClick={() => handleLogin(instance)}>
  				{contexto.user.iniciado?contexto.user.fullName: textoBoton}          
  			</button>
  			{userOpciones()}
		</div>
	</React.Fragment>

	);
}

