import React, {useState, useContext, useEffect} from 'react'
import request from '../shared/helpers/request';
import { toast } from 'react-toastify';
import UserContext from './../../application/userContext';
import {useInput, useSelect, useHidden, useCaras} from './Controles'
import {useHistory} from "react-router-dom";

export default function Encuesta(props){	
	const [preguntas, setPreguntas] = useState([]);
	const [titulo, setTitulo] = useState('');
	const [encuestando, setEncuestando] = useState(true);
	const [retorno, setRetorno] = useState('');
	const contexto = useContext(UserContext);
	const history = useHistory();

	const dibujarPreguntas = (pregunta, indice) => {

		switch(pregunta.tipo){
			case 'text':return (<React.Fragment key={indice}>
								 {useHidden(indice, 'preg[]', pregunta.id)}
								 {useInput(indice, pregunta.pregunta, 'resp[]', 'text')}
								</React.Fragment>);
						 break;
			case 'bool':return (<React.Fragment key={indice}>
								 {useHidden(indice, 'preg[]', pregunta.id)}
								 {useSelect(indice, pregunta.pregunta, 'resp[]', ['Si', 'No'])}
								</React.Fragment>);
						 break;
			case 'caras':return (<React.Fragment key={indice}>
								 {useHidden(indice, 'preg[]', pregunta.id)}
								 {useCaras(indice, pregunta.pregunta, 'resp[]')}
								</React.Fragment>);
						 break;
		}
		
			   
	};

	const handleSubmit = (event) => {		
		event.preventDefault();
		const objetoEnviar = {
			"definicion": {
        		"encuesta": {            
            		"tramite_gp_id": props.tramite_gp_id,
            		"tramite_id": props.tramite_id,
					"respuestas": []
        		}
    		}
		};
		const data = new FormData(event.target);
		let i = 0;		
		for (var x of data.entries()) {    		      
              if (i%2 == 0){
              	var resp = {"pregunta": 0, "respuesta": 0};
              	resp.pregunta = x[1];
              }              	
              else{
              	resp.respuesta = x[1];              	
              	objetoEnviar.definicion.encuesta.respuestas.push(resp);
              }
              i++;
    	} 
    	//console.log(objetoEnviar);
    	request.post('api/rutcore/encuestas', objetoEnviar, null)
    		   .then(resp => {
    			   if(resp.status == 200){
					 toast.success('Encuesta Enviada! Gracias por responder, esta informacion nos ayuda a mejorar!', {
							position: "top-center",
							autoClose: false,
							hideProgressBar: false,
							closeOnClick: true,
							pauseOnHover: true,
							draggable: true,
							progress: undefined,
					 });
    			   	 const u = {...contexto.user, encuesta: false};    			   	 
    			   	 contexto.setUsuario(u);
    			   	 //localStorage.setItem('user', JSON.stringify(u));
    			   	 setEncuestando(false);					 
					 history.push('/');
    			   }    			   	
    		   })
    		   .catch(e => {
					toast.error('Error al enviar Encuesta. Verifique su conexion.', {
							position: "top-center",
							autoClose: false,
							hideProgressBar: false,
							closeOnClick: true,
							pauseOnHover: true,
							draggable: true,
							progress: undefined,
					 });
    			   	 setEncuestando(false);
    		   });
	};


	useEffect(()=>{		
		//if (contexto.user.iniciado && !contexto.user.encuesta) window.location = process.env.REACT_APP_BASEURL;
		request.get('api/rutcore/encuestas', null)
		   .then(resp => resp.data)
		   .then(data => {
		   	setTitulo(data.tramite);
		   	setPreguntas(data.preguntas);
		   });		
	}, [/*contexto.user*/]);

	return encuestando?(			 
			 <form onSubmit={handleSubmit}>
			  <h2>Por favor responda la siguiente Encuesta para poder continuar:</h2>
			  <h1>{titulo}</h1>
			   {preguntas? preguntas.map((x, y) => dibujarPreguntas(x, y)): ''}
			   <div className="row justify-content-center align-items-center">
			 	{preguntas.length>0?<button className="btn btn-primary" type="submit">Enviar</button>:''}
			   </div>
		     </form>) : retorno;
}


Encuesta.defaultProps = {
	tramite_gp_id: 0,
	tramite_id: 1
}