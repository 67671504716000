import React, {useState} from 'react'

const UserContext = React.createContext({});

export function UserContextProvider({children}){
	const [user, setUser] = useState({
                                     iniciado: false,
                                     fullName: null,
                                     email: null,
                                     id: null,
                                     legajos: [],
                                     encuesta: false,
                                     roles: [],
                                     currentPage: {title: null, from: null}
                                    });
      const [tramites, setTramites] = useState([]);

      const setUsuario = (u)=>{
            setUser(u);
            sessionStorage.setItem('user', JSON.stringify(u));
            //console.log(localStorage.getItem('user'));
      }
      const delUsuario = () => {
            setUser({...user, iniciado:false, "fullName": null, "email": null, "id": null, encuesta: false, roles: []});
            sessionStorage.removeItem('user');
            sessionStorage.removeItem('expira');
      }
	return <UserContext.Provider value={{user, setUsuario, delUsuario, tramites, setTramites}}>
		   {children}
		 </UserContext.Provider>
}


export default UserContext;