import React, {useState, useLayoutEffect } from 'react'
import request from '../../../shared/helpers/request'

export default function Usuarios(props){
    const clearUser = {dni:'', apellido:'', nombres:'', correo:'', legajos:[]};
    const clearLegajo = {legajo:'', especialidad:'', ingreso:'', plan:''};
    const [editingIndex, setEditingIndex] = useState(0); 
    const [editando, setEditando] = useState(false);
    const [busqueda, setBusqueda] = useState('');
    const [editUser, setEditUser] = useState(clearUser);
    const [newLegajo, setNewLegajo] = useState(clearLegajo);
    const [usuarios, setUsuarios] = useState([]);
    const [especialidades, setEspecialidades] = useState([]);

    const traerEspecialidades = () => {
      request.get('api/rutcore/especialidades')
             .then(x => {
                setEspecialidades(x.data);
             })
    }

    const traerUsuarios = () => {
      request.get('api/rutcore/usuarios/'+busqueda)
             .then(x => {
                setUsuarios(x.data.map(u => {
                            return (
                                    { dni: u.dni, 
                                      apellido: u.apellido, 
                                      nombres: u.nombre, 
                                      correo: u.email_institucional, 
                                      legajos: u.get_legajos.map(l => ({
                                                                        legajo: l.nro_Legajo,
                                                                        especialidad: l.especialidad_id_especialidad,
                                                                        especialidad_nombre: especialidades.find(x => x.id_Especialidad == l.especialidad_id_especialidad).descripcion,
                                                                        ingreso: l.ingreso,
                                                                        plan: l.plan
                                                                      }))})
                                    }
                ))
             })
    }

    const cancelarUser = () =>{
        setEditando(!editando);
        setEditUser(clearUser);
    }

    const agregarUser = () =>{
        setEditando(!editando);
        setEditUser(clearUser);
    }

    const editarUsuario = (y) =>{
        setEditUser(usuarios[y]);
        setEditingIndex(y);
        setEditando(!editando);
    }

    const guardarUser = async () =>{
      request.get('api/rutcore/usuarios/'+editUser.dni)
             .then(x => {
                if (x.data.length)
                  if (window.confirm('Confirma editar este usuario?'))
                  request.put('api/rutcore/usuarios', editUser)
                  .then(x => {
                       if(x.data.dni){
                         console.log(x.data);
                         traerUsuarios();
                         alert('Usuario editado')
                       }
                     });
                  else;
                else {
                  request.post('api/rutcore/usuarios', editUser)
                               .then(x => {
                                    if(x.data.dni){
                                      console.log(x.data);
                                      let u = usuarios;
                                      u.push(editUser);
                                      setUsuarios(u);
                                    }
                                  });
                }
                setEditando(!editando);
             })      
    }

    const addLegajo = () =>{
      const legajos = editUser.legajos;
      legajos.push(newLegajo);
      setEditUser({...editUser, legajos: legajos});
      setNewLegajo(clearLegajo);
    }

    useLayoutEffect (() => {
      traerEspecialidades();
    }, [])

    return (
<div className="w-200">
  <div className="input-group mb-3">
    <input type="text" className="form-control" placeholder="Ingrese DNI o Apellido y Nombres" aria-describedby="basic-addon2" disabled={editando} value={busqueda} onChange={e => setBusqueda(e.target.value)}/>
    <div className="input-group-append">
        <button className="btn btn-success" type="button" disabled={editando} onClick={traerUsuarios}>Buscar</button>
    </div>
    <div className="input-group-append">
        <button className="btn btn-primary" type="button" onClick={agregarUser} disabled={editando}>Agregar</button>
    </div>
  </div>
  {!editando && (
  <table className="table">
  <thead className="thead-dark">
    <tr>
      <th scope="col">DNI</th>
      <th scope="col">Apellido y Nombres</th>
      <th scope="col">Email</th>
      <th scope="col">Opciones</th>
    </tr>
  </thead>
  <tbody>
    {usuarios.map((x, y) => 
      (<tr key={y}>
        <th scope="row">{x.dni}</th>
        <td>{x.apellido+', '+x.nombres}</td>
        <td>{x.correo}</td>
        <td><div className="btn-group" role="group" aria-label="Basic example">
            <button type="button" className="btn btn-danger btn-sm"><i className="fa fa-trash" aria-hidden="true"></i></button>
            <button type="button" className="btn btn-warning btn-sm" onClick={()=>editarUsuario(y)}><i className="fa fa-pencil" aria-hidden="true"></i></button>
           </div></td>
      </tr>)
    )}
    
  </tbody>
</table>)}
{editando && (<div>
<hr/>
<fieldset>
    <legend>Usuario</legend>
<form>
  <div className="form-row">
    <div className="form-group col-md-6">
      <label htmlFor="usuarioDNI">D.N.I.</label>
      <input type="number" className="form-control" id="usuarioDNI" placeholder="D.N.I." value={editUser.dni} onChange={(e) => setEditUser({...editUser, dni: e.target.value})}/>
    </div>
    <div className="form-group col-md-6">
      <label htmlFor="usuarioEmail">Email</label>
      <input type="email" className="form-control" id="usuarioEmail" placeholder="Email Institucional" value={editUser.correo} onChange={(e) => setEditUser({...editUser, correo: e.target.value})}/>
    </div>    
  </div>
  <div className="form-row">
  <div className="form-group col-md-6">
    <label htmlFor="usuarioApellido">Apellido</label>
    <input type="text" className="form-control" id="usuarioApellido" placeholder="Apellido" value={editUser.apellido} onChange={(e) => setEditUser({...editUser, apellido: e.target.value})}/>
    </div>
    <div className="form-group col-md-6">
    <label htmlFor="usuarioNombres">Nombres</label>
    <input type="text" className="form-control" id="usuarioNombres" placeholder="Nombres" value={editUser.nombres} onChange={(e) => setEditUser({...editUser, nombres: e.target.value})}/>
  </div>
  </div>
  <hr/>
  <div className="form-row">
    <div className="form-group col">
      <label htmlFor="legajoLegajo">Legajo</label>
      <input type="text" className="form-control" id="legajoLegajo" value={newLegajo.legajo} onChange={(e) => setNewLegajo({...newLegajo, legajo: e.target.value})}/>
    </div> 
    <div className="form-group col">        
      <label htmlFor="legajoEspecialidad">Especialidad</label>
      <select id="legajoEspecialidad" className="form-control" value={newLegajo.especialidad?newLegajo.especialidad:-1} onChange={(e) => setNewLegajo({...newLegajo, especialidad: e.target.value, especialidad_nombre: especialidades.find(x => x.id_Especialidad == e.target.value).descripcion})}>
        <option value={-1} disabled hidden>Elija una...</option>
        {especialidades.map((x, y) => 
          (<option key={y} value={x.id_Especialidad}>{x.descripcion}</option>)
        )}
      </select>
    </div>
    <div className="form-group col">
      <label htmlFor="legajoPlan">Plan</label>
      <input type="number" className="form-control" id="legajoPlan" value={newLegajo.plan} onChange={(e) => setNewLegajo({...newLegajo, plan: e.target.value})}/>
    </div>
    <div className="form-group col">
      <label htmlFor="legajoIngreso">Ingreso</label>
      <input type="number" className="form-control" id="legajoIngreso" value={newLegajo.ingreso} onChange={(e) => setNewLegajo({...newLegajo, ingreso: e.target.value})}/>
    </div>   
    <div className="form-group col mt-4"> 
      <button type="button" className='btn btn-secondary mt-2' onClick={addLegajo}>Agregar Legajo</button>
    </div>
  </div>
  <table className="table table-sm">
  <thead>
    <tr>
      <th scope="col">Legajo</th>
      <th scope="col">Especialidad</th>
      <th scope="col">Plan</th>
      <th scope="col">Ingreso</th>
    </tr>
  </thead>
  <tbody>
    {editUser.legajos.map((x, y) => (
    <tr key={y}>
      <th scope="row">{x.legajo}</th>
      <td>{x.especialidad_nombre?x.especialidad_nombre:x.especialidad}</td>
      <td>{x.plan}</td>
      <td>{x.ingreso}</td>
    </tr>))}
  </tbody>
</table>
<div className="form-group text-right">
  <button type="button" className="btn btn-primary" onClick={guardarUser}>Guardar</button>
  <button type="button" className="btn btn-danger ml-2" onClick={cancelarUser}>Cancelar</button>
</div>
</form>
</fieldset>
</div>)}</div>);
}