 	import React from 'react';
  import Table from './Table';
  import CaraOk from '../../assets/images/caraok.png'
  import CaraFail from '../../assets/images/carafail.png'

  export const useInput = function (key, titulo, name, type) {   		
   		const input = (<div key={key} className="form-group">
   						<label htmlFor={name}>{titulo}</label>
   						<input className="form-control" id={name} name={name} type={type} required/>
   					  </div>);
   		return input;      
 	}

  export const useHidden = function (key, name, value) {      
      const hidden = <input id={name} name={name} type="hidden" value={value}/>;
      
      return hidden;      
  }

 	export const useSelect = function (key, titulo, name, options) {   		
   		const select = (<div key={key} className="form-group">
   						<label htmlFor={name}>{titulo}</label>
   						<select className="form-control" id={name} name={name}>
   							{options.map((o, i) => <option key={i}>{o}</option>)}
   						</select>
   					  </div>);
   		return select;
 	}

  export const useTable = (key, titulo, name, cols) => {
      return <Table clave={key} name={name} titulo={titulo} cols={cols}/>
  }

  export const useCaras = (key, titulo, name) => {
   const caras = (<div key={key} className="form-group">
              <label htmlFor={name}>{titulo}</label>              
              <div className="row justify-content-center align-items-center">
                <img className="m-3" src={CaraFail} alt=""/>
                <div className="form-check form-check-inline"> 
                  <input className="form-check-input" id={name} name={name} type="radio" value="0" required/>
                </div>
                <div className="form-check form-check-inline"> 
                  <input className="form-check-input" id={name} name={name} type="radio" value="1"/>
                </div>
                <div className="form-check form-check-inline"> 
                  <input className="form-check-input" id={name} name={name} type="radio" value="2"/>                
                </div>
                <div className="form-check form-check-inline"> 
                  <input className="form-check-input" id={name} name={name} type="radio" value="3"/>
                </div>
                <div className="form-check form-check-inline"> 
                  <input className="form-check-input" id={name} name={name} type="radio" value="4"/>
                </div>
                <img src={CaraOk} alt=""/>
              </div>
              
            </div>);
      return caras;
  }

  export const useLegajo = (user) => {
    return <div key={-1} className="form-group">
              <label htmlFor="legajo">Legajo</label>
              <select className="form-control" id="legajo" name="legajo">
                {user.legajos.map((o, i) => <option key={i}>{o.legajo+' - '+o.especialidad}</option>)}
              </select>
           </div>
  }

  