import React, {useContext} from 'react';
import UserContext from './../application/userContext';
import {Link} from "react-router-dom";

export default function Categoria(props){	
	const {user, setUsuario} = useContext(UserContext);
	return (
		<li key={props.clave} className="nav-item dropdown" style={{'display': props.opciones.length===0?'none':'initial'}}>
        <a className="btn btn-outline-light nav-link dropdown-toggle" id="navbarScrollingDropdown" role="button" data-toggle="dropdown" aria-expanded="false">
          {props.titulo}
        </a>
        <ul className="dropdown-menu" aria-labelledby="navbarScrollingDropdown">
          {props.opciones.map((x, y) => <li key={y}><Link to={user.encuesta?"/encuesta":x.id===-9999?"/admin":"/tramites"} className="dropdown-item" 
          			onClick={()=>{if (x.id!== -9999) setUsuario({...user,
                                        currentPage: 
                                            {idTramite: x.id, 
                                             title:x.definicion.titulo, 
                                             proyecto:x.definicion.proyecto,
                                             indicador:x.definicion.indicador,
                                             form:x.definicion.page, 
                                             time: x.definicion.dias, 
                                             agent: x.definicion.agente, 
                                             aux: x.definicion.aux}})}}>
                  {x.definicion.titulo}</Link></li>)}                   
        </ul>
      </li>      
	);
}


Categoria.defaultProps = {
	titulo : "categoria",
	opciones : [],
	clave: 0
}