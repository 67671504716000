import React, {useState, useEffect} from 'react'
import {useLocation} from "react-router-dom";
import request from './shared/helpers/request'
import Spinner from './shared/helpers/Spinner'


export default function Verificador(){
    const { search } = useLocation();
	const params = new URLSearchParams(search);
	const id = params.get('id')?params.get('id'):null;
  	const [idTramite, setIdTramite] = useState('');
  	const [tramite, setTramite] = useState(null);
  	const [buscarText, setBuscarText] = useState('Buscar');
        
    useEffect(()=>{
      setIdTramite(id);
      if (id) buscarTramite(id);
    },[]);
    

  	const buscarTramite = idTramite => {
   		setBuscarText(<Spinner/>);
   		request.get('api/rutcore/tramites/verificar?cod='+idTramite)
          		.then( response => {
              		//console.log(response.data);
                  if (response.data.titulo)
              		  setTramite(response.data);
                  else
                    setTramite(null);
                  if (response.data.error) alert(response.data.error);
              		setBuscarText('Buscar');})
          		.catch(e => {
            		alert('No se pudo validar ese codigo. Verifique caracter por caracter.');
            		setBuscarText('Buscar'); 
          		});
  	};

  	const descargarArchivo = (idTramite, idArchivo, nombreArchivo) => {
  		//console.log('api/rutcore/adjuntos/'+idArchivo+': '+nombreArchivo);
		idArchivo = idTramite.adjuntos[0].id;
		nombreArchivo = idTramite.adjuntos[0].nombre;
		  request.getFile('api/rutcore/adjuntos/'+idArchivo, nombreArchivo);
      /*request.put('api/rutcore/tramites/'+idTramite, 
                {definicion: {					  
                      issue: {
                          status_id: 3, done_ratio: 100}}}, null)
            .then(x=> console.log(x));*/
			console.log(idTramite);
  	}
	
	return (
		<React.Fragment>		
        <h3>Verificador de Documentos</h3>
		<div className="input-group md-form form-sm form-1 pl-0 mb-3">  			        
  			<input className="form-control my-0 py-1" type="text" placeholder="Codigo de Documento a Verificar" aria-label="Search" value={idTramite} onChange={e => setIdTramite(e.target.value)}/>
  			<div className="input-group-append">
    			<button className="btn btn-outline-success" type="button" onClick={()=>buscarTramite(idTramite)}>{buscarText}</button>
  			</div>
		</div>		
        <div className="input-group md-form form-sm form-1 pl-3 mb-3">       
            <fieldset>
                <legend className="bg-secondary text-light">{tramite? tramite.titulo: ''}</legend>
                <p>{tramite? 'Fecha: '+Intl.DateTimeFormat('es').format(Date.parse(tramite.fecha_inicio)):''}</p>
                <p>{tramite? 'Estado: Verificado':''}</p>
            </fieldset> 
        </div>
		 {tramite?
        <button className="btn btn-outline-primary d-block"                                 
                onClick={() =>descargarArchivo(tramite, 0, 0)}>
                Descargar Archivo Verificado
        </button>:null}
    </React.Fragment>
	);
}