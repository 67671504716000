import React, {useState, useEffect, useContext} from 'react'
import Usuarios from './admin/cruds/Usuarios'
import UserContext from './../../application/userContext';
import {BrowserRouter, Switch, Route, Link, useHistory} from "react-router-dom";



export default function Admin(props){
    const history = useHistory();
    const contexto = useContext(UserContext);   
  
 if (contexto.tramites && contexto.tramites.find(x => x.categoria === '__ADMIN__'))
    return (
      
        <Usuarios/>
    );
 else return <div></div>
}

