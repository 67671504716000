import React, {useContext, useEffect, useState} from 'react'
import Navbar from './components/Navbar';
import Carousel from './components/Carousel'
import FooterUtn from './components/FooterUtn'
import Cae from './components/pages/Cae'
import Admin from './components/pages/Admin'
import Nouser from './components/pages/noUser'
import Encuesta from './components/pages/Encuesta'
import MisTramites from './components/MisTramites'
import Verificador from './components/Verificador'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Tramites from './assets/images/tramites.png'
import Tiempo from './assets/images/tiempo.png'
import Constancias from './assets/images/constancias.png'
import UserContext, {UserContextProvider} from './application/userContext'
import {BrowserRouter, Switch, Route} from "react-router-dom";

function App() {
  
  const tramite = {nombre: "",
                   cols:['id_Tramite', 'descripcion'],
                   campos:[],
                   get:"api/rutcore/tramites",
                   put:"",
                   post:"",
                   delete:""
                  }
  const contexto = useContext(UserContext);


  
  return (
    <div className="App">
      <header className="App-header">
      </header>        
        <BrowserRouter>
        <UserContextProvider>           
          <Navbar tramites={contexto.tramites}/>
          <section style={{minHeight: 400}}>                    
            <div className="container mt-4">
             <div className="row justify-content-center align-items-center">
              <ToastContainer></ToastContainer>                       
               <Switch>
                 <Route path="/verificar">
                    <Verificador/>
                 </Route>
                 <Route path="/encuesta">
                    <Encuesta/>
                 </Route>
                 <Route path="/tramites">
                    <Cae/>
                 </Route>                 
                 <Route path="/mistramites">
                    <MisTramites/>
                 </Route>
                 <Route path="/nouser">
                    <Nouser/>
                 </Route>
                 <Route path="/admin">
                    <Admin/>
                 </Route>
                 <Route path="/">
                    <Carousel img={[Tramites, Tiempo, Constancias]}/>
                 </Route>                                  
               </Switch>
             </div>
            </div>
          </section>    
        </UserContextProvider>          
        </BrowserRouter>
      <FooterUtn/>
    </div>
  );
}

export default App;
