import React, {useState, useContext, useEffect} from 'react'
import request from '../shared/helpers/request'
import TramiteBase from '../shared/helpers/tramiteBase'
import Spinner from '../shared/helpers/Spinner'
import { toast } from 'react-toastify';
import UserContext from './../../application/userContext';
import {useInput, useSelect, useTable, useLegajo} from './Controles'
import {useHistory} from "react-router-dom";

export default function Cae(){
//41 id SAE Prueba project
  const {user, setUsuario} = useContext(UserContext);
	const [texto, setTexto] = useState('Iniciar Tramite'); 
  const history = useHistory();
	
  const caracteres_especiales = function (formData) {
    let formCopy = {...formData};
    for(let key in formData) {
      
      if(formData[key] && typeof formData[key] == "string" ){   
        formCopy[key] = formData[key].replace(/[\\]+/g, "\\\\").replace(/["]+/g, "\\\"").replace(/\r?\n/g, "\\n");
      } 
      else{
        formCopy[key] = formData[key]
      }

    }    
    return formCopy;
  };

	const enviarTramite = (objetoenviar, funcion = null)=>{
		
			setTexto('Aguarde mientras se procesa su solicitud...');
      
      objetoenviar.definicion.issue.description =
      objetoenviar.definicion.issue.description.replace(/!#[\w :]*/, '');
      
      const errorCallback = error => {      			
      			setTexto('Iniciar Tramite');
      			//console.log(error);
    		};

 		  request.post(`api/rutcore/tramites`,objetoenviar, null)
    		 	   .then(response => {
    		 	   		//console.log(response.data);
    		 	   		if (response.data.gp_peticion_id !== 0){
    		 	   			  setTexto('Iniciar Tramite');
                    toast.success('Tramite Iniciado. Nro de Referencia: '+response.data.gp_peticion_id, {
                      position: "top-center",
                      autoClose: false,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                      });
                  if (funcion) funcion();
   		 	   		  }
        				//dispatch(saveTramiteSuccess(response.data));
        				//callbackSave();
      				},
      				errorCallback
    		    );
  };		
		

	const armarFormulario = (form) => {
		const result = [];
    //console.log(form);
    const json = typeof(form)!== 'object' ? JSON.parse(form) : form;
    if (typeof(json) !== 'object') return result;
    var i = 0;
		for (var x in json) {
        i++;
    		if (json.hasOwnProperty(x)) 
    			switch(typeof(json[x])){
    				case 'string' : if (!isNaN(Date.parse(json[x]))) result.push(useInput(i, x, x, 'date'));
                            else if (json[x]==='password') result.push(useInput(i, x, x, 'password'));
                                 else result.push(useInput(i, x, x, 'text'));
                            break;
    				case 'number' :if (x == 'Legajo') result.push(useLegajo(user));
                           else result.push(useInput(i, x, x, 'number')); break;
    				case 'boolean' :result.push(useSelect(i, x, x, ['si', 'no'])); break;
    				case 'object' : if (json[x].constructor === [].constructor) result.push(useSelect(i, x, x, json[x]));
                            else if (json[x].constructor === ({}).constructor) result.push(useTable(i, x, x, json[x]));
                            break;
            default: break;
    			}		          	
		}
		return result;
	};

  const handleSubmit = (event) => {
    event.preventDefault();
    var descripcion = user.currentPage.title+'\n'
    +'____________________________________\n'
    +'Solicitante: '+user.fullName+'\n'    
    +'Correo Electronico: '+user.email+'\n';

    const data = new FormData(event.target);
    
    let passInputs = Array.from(document.querySelectorAll('input[type=password]')); 
    for (var x of data.entries()) {          
        descripcion += (passInputs.find(inp => inp.name === x[0])?'!#':'')+
                       x[0]+': '+x[1]+'\n';
    } 

    descripcion += '____________________________________\n'
                  +'Id: '+user.id+'\n';

    //console.log(descripcion);
    let objetoenviar = new TramiteBase(user.currentPage.idTramite, user.currentPage.proyecto, 
                            user.currentPage.indicador, 
                            user.currentPage.title, 
                            descripcion,
                            user.currentPage.time, user.currentPage.agent, 2, 0, user.email).getTramite();
    
    if (user.currentPage.aux){
      setTexto('Aguarde mientras se procesa su solicitud...');     
      const u = {...user, encuesta:true};
      objetoenviar.definicion.issue.done_ratio = 100;
      objetoenviar.definicion.issue.status_id = 2;
      objetoenviar.definicion.adjuntos = [ data];  
      request.post('api/rutcore/'+user.currentPage.aux, objetoenviar, null, true)
              .then(( { data } ) => {
                /*enviarTramite(objetoenviar, () => {                  */
                  const downloadUrl = window.URL.createObjectURL(new Blob([data]));                  
                  const link = document.createElement('a');
                  link.href = downloadUrl;
                  link.setAttribute('download', user.currentPage.title+'.pdf');
                  document.body.appendChild(link);
                  link.click();
                  link.remove();
                  history.push('/encuesta');
                  setUsuario(u); 
                /*});*/
              })
              .catch(e => {
                if (e.response){
                  e.response.data.text()
                  .then(t => {                      
                      setTexto('Iniciar Tramite');
                      t = JSON.parse(t);                      
                      toast.error('Error: '+t.message, {
                      position: "top-center",
                      autoClose: false,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                      });
                  })
                }
              });              
    }else enviarTramite(objetoenviar);
    
    //console.log(objetoenviar);
  }

	return (        
        user.iniciado && user.currentPage.title?     
        <div className="m-90 d-block p-3 border border-primary overflow-y">       
       <form onSubmit={handleSubmit}>
        
        <fieldset>
          <legend>{user.currentPage.title}</legend>
				  {armarFormulario(user.currentPage.form).map(e => e)}
        </fieldset>
        <div className="row justify-content-center align-items-center overflow-auto">
          <button className="btn btn-primary" type="submit" disabled={texto != 'Iniciar Tramite'}>{texto}</button>                    
        </div>        
        <div className="row justify-content-center align-items-center overflow-auto" 
             style={{height: texto != 'Iniciar Tramite'?'50px':'0px'}}>
          {texto != 'Iniciar Tramite'?<Spinner/>:''}
        </div>
			 </form>
       <br/>
       <p>{user.currentPage.time>0?'Este tramite tiene una demora estimada de '+
          (user.currentPage.time<4?
            ' '+(user.currentPage.time*24)+'hs': ' '+user.currentPage.time+' dias habiles.'):
          'Este tramite se resolvera a la brevedad.'}<br/>Revise su correo electronico para hacer el seguimiento del mismo.</p>
       </div>:null
			);
}
