import React, { Component } from 'react';

class FooterUtn extends Component {

  render() {
    return (
      <footer className="navbar  bg-dark">
<div className="container">
          <div className="container-inner">
            <div className="row text-white">
              
                <div className="sp-column ">
                  <div className="sp-module ">
                    <div className="sp-module-content">
                      <div className="custom">
                        <p><img src="https://utn.edu.ar/images/logoutnwhite.png" alt="" width="189" height="49" /></p>
                        <p style={ { lineHeight: '0.1em', fontSize: '0.85em' } }>UTN-FRT</p>
                        <p style={ { lineHeight: '0.1em', fontSize: '0.85em' } }><a>Rivadavia 1050 - San Miguel de Tucumán</a></p>
                        <p style={ { lineHeight: '0.1em', fontSize: '0.85em' } }><a>Tucumán, Argentina</a></p>
                        <p style={ { lineHeight: '0.1em', fontSize: '0.85em' } }><a style={ { color: 'white' } }href="tel: +54 0381 4307385">(0381) 4217150 / 4307387 / 4307385 - Interno 200</a></p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div id="sp-bottom2" className="col-sm-col-sm-6 col-lg-7 ">
                <div className="sp-column ">
                  <div className="sp-module ">
                    <div className="sp-module-content">
                    </div>
                  </div>
                </div>
              
            </div>
          </div>
        </div>
      </footer>
      
    );
  }
}

export default FooterUtn;
